import LAYOUT from '@/layout/layout';
import moduleLayout from '@/modules/ActionJail/layout/Layout.vue';

export default [
  {
    path: '/action_jail',
    component: LAYOUT.base,
    children: [
			{
				path: '',
				component: moduleLayout,
				children: [
					{
						path: '',
						name: 'ActionJailOnBoardingScreen',
						component: () => import(/* webpackChunkName: 'action-jail-home' */ '@/modules/ActionJail/views/OnBoardingScreen.vue'),
					},
				],
			},
      {
        path: 'actions',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'Actions',
            component: () => import(/* webpackChunkName: 'action-jail-tab' */ '@/modules/ActionJail/views/ActionsTab.vue'),
          },
        ],
      },
    ],
  },
];
