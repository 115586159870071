/**
 * Created by Bezsmertnyi Ievgen on 12.09.2020.
 */

const getTranslateFunc = function () {
  let i18n;

  try {
    i18n = require('@/lang/i18n.js').default;
  } catch (error) {
    // console.log(error);
  }

  return i18n
    ? (translateAlias) => i18n.t(translateAlias)
    : (translateAlias) => translateAlias;
};

const getTranslate = getTranslateFunc();

//Должен называться так же как и в modules_components_obj
let module_name = 'BroadcastUsers';

let module_settings = {
  module_name,
};

//Пункты в меню
module_settings.menu_items = [
  {
    title: getTranslate('common.name'),
    module: module_name,
    icon: 'mdi-card-account-details-outline',
    show: true,
    route: 'clients',
  },
];

//Табы модуля
module_settings.tabs_items = {};

module_settings.tabs_items[module_name] = {
  use_tabs: true,
  tabs_items: [
		{
			tab: getTranslate('common.name'),
			content: '',
			show: true,
			type: 'clients',
			route: '',
		},
    {
      tab: getTranslate('common.contacts'),
      content: '',
      show: true,
      type: 'contacts',
      route: 'contacts',
    },
		{
			tab: getTranslate('common.visitors'),
			content: '',
			show: true,
			type: 'visitors',
			route: 'visitors',
		},
  ],
};

//Подключенные компоненты
module_settings.components = [
  {
    name: module_name,
    path: 'Component/Broadcast.vue',
  },
];

module.exports = { module_settings };
module.exports.default = { module_settings };
