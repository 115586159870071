import LAYOUT from '@/layout/layout';
import moduleLayout from '@/modules/BroadcastUsers/layout/Layout.vue';

export default [
  {
    path: '/clients/',
    component: LAYOUT.base,
    children: [
			{
				path: '',
				component: moduleLayout,
				children: [
					{
						path: '',
						name: 'Clients',
						component: () => import(/* webpackChunkName: 'clients' */ '@/modules/BroadcastUsers/views/Clients.vue'),
					},
				],
			},
      {
        path: ':id/contacts',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'BroadcastUsers',
            component: () => import(/* webpackChunkName: 'broadcast-contacts' */ '@/modules/BroadcastUsers/views/Contacts.vue'),
          },
        ],
      },
			{
				path: ':id/visitors',
				component: moduleLayout,
				children: [
					{
						path: '',
						name: 'Visitors',
						component: () => import(/* webpackChunkName: 'visitors' */ '@/modules/BroadcastUsers/views/Visitors.vue'),
					},
				],
			},
    ],
  },
];
